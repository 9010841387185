import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cls from 'classnames';
import { animated } from 'react-spring';
import { useTransitionUp } from 'hooks';
import { DATA } from './Contact/_constant';
import NavbarContext from '../../layouts/NavBar/NavbarContext';

const Contact = ({ className, id, ...rest }) => {
  const { activeSection } = React.useContext(NavbarContext);
  const props = useTransitionUp(activeSection === id);
  return (
    <div
      className={cls('flex flex-col items-center justify-center', className)}
      id={id}
      {...rest}
    >
      <animated.div
        data-label="contact-card"
        className="border-2 rounded leading-loose shadow-md md:text-2xl"
        style={props}
      >
        <div data-label="contact-details" className="flex p-8">
          <div className="text-gray-500 flex flex-col items-center mr-8">
            {Object.keys(DATA).map((key) => (
              <div key={key}>
                <FontAwesomeIcon icon={DATA[key].icon} />
              </div>
            ))}
          </div>
          <div className="m-auto text-gray-700">
            {Object.keys(DATA).map((key) => (
              <p key={key}>{DATA[key].value}</p>
            ))}
          </div>
        </div>
      </animated.div>
    </div>
  );
};

Contact.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Contact.defaultProps = {};

export default Contact;
