import { useSpring } from 'react-spring';

const useTransitionUp = isCurrentSection => {
  return useSpring({
    from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
    to: {
      opacity: isCurrentSection ? 1 : 0,
      transform: isCurrentSection
        ? 'translate3d(0,0,0)'
        : 'translate3d(0,40px,0)',
    },
  });
};

export default useTransitionUp;
