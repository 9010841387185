import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { experiencesDb } from '../../lib/data';

const Internships = ({ className, data, ...rest }) => {
  return (
    <div {...rest}>
      {Object.values(data).map((i) => (
        <div
          key={i.id}
          className={cls('flex items-center', i.background, className)}
        >
          <div className="flex items-center flex-col md:flex-row">
            <div className="flex-1 text-center">
              <img
                style={{ width: 400 }}
                className="inline"
                src={i.logo}
                alt="company-logo"
              />
            </div>
            <div className="flex-1">
              <div data-label="title" className="my-4 inline-block">
                <p
                  className={cls(
                    'text-2xl md:text-4xl py-2 font-bold underline-spacing after:left-0 after:right-0',
                    i.text,
                    i.underline,
                  )}
                >
                  {i.role}
                </p>
                <p className={cls('italic', i.periodText)}>{i.period}</p>
              </div>
              <div data-label="description">
                <div className="flex flex-col gap-4">
                  <ul className="list-disc flex flex-col gap-4">
                    {i.long.map((description, idx) => (
                      <li
                        // eslint-disable-next-line react/no-array-index-key
                        key={idx}
                        className={cls('text-xl', i.textDescription)}
                      >
                        {description}
                      </li>
                    ))}
                  </ul>
                  <div className="flex gap-2 items-center">
                    {i.technologies?.map(({ label, src, link }) => (
                      <div key={label} className="inline-block">
                        <div className="tw-tooltip relative flex flex-col p-2 items-center md:hover:zoom-in-lg">
                          <a
                            className="relative"
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img alt={label} width={30} height={30} src={src} />
                            <p
                              data-label="tooltip-content"
                              className={cls(
                                'absolute hidden md:block md:my-1 text-xs text-center p-1 rounded whitespace-no-wrap',
                                i.tooltip,
                              )}
                            >
                              {label}
                            </p>
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

Internships.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

Internships.defaultProps = {
  data: experiencesDb,
};

export default Internships;
