const CONTACT_FIELDS = {
  NAME: 'NAME',
  EMAIL: 'EMAIL',
  ADDRESS: 'ADDRESS',
  PHONE: 'PHONE',
};

export const DATA = {
  [CONTACT_FIELDS.NAME]: {
    value: 'Jason Gunawan Limantoro',
    label: 'Name',
    icon: 'id-card',
  },
  [CONTACT_FIELDS.EMAIL]: {
    value: 'jasonlimantoro99@gmail.com',
    label: 'Email',
    icon: 'envelope',
  },
};
