import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { Link } from 'react-scroll';

const NavItem = React.forwardRef(({ children, className, ...rest }, ref) => {
  return (
    <Link
      className={cls('cursor-pointer', className)}
      hashSpy
      smooth
      spy
      {...rest}
    >
      <span ref={ref}>{children}</span>
    </Link>
  );
});

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

NavItem.defaultProps = {};

export default NavItem;
