import React from 'react';
import SEO from 'components/SEO';
import NavBar from '../layouts/NavBar';
import NavbarProvider from '../layouts/NavBar/NavbarProvider';
import Banner from './Home/_Banner';
import Projects from './Home/_Projects';
import Experiences from './Home/_Experiences';
import Contact from './Home/_Contact';
import Education from './Home/_Education';

const Home = () => {
  return (
    <div>
      <SEO />
      <NavbarProvider>
        <NavBar id="navbar" />
        <main>
          <Banner id="banner" className="section" />
          <Education id="education" className="section" />
          <Experiences id="experiences" className="section" />
          <Projects id="projects" className="section" />
          <Contact id="contact" className="section" />
          <div className="text-center p-4 text-gray-700">
            <p>
              Built with <code className="code">React</code>,{' '}
              <code className="code">Gatsby</code>, and{' '}
              <code className="code">Tailwindcss</code>
            </p>
          </div>
        </main>
      </NavbarProvider>
    </div>
  );
};

Home.propTypes = {};

export default Home;
