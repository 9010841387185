import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const Banner = ({ className, ...rest }) => {
  return (
    <div
      className={cls(
        className,
        'flex flex-col items-center justify-center text-left font-bold text-3xl md:text-5xl',
      )}
      {...rest}
    >
      <div className="text-left leading-relaxed">
        <p>Hi, there. I'm Jason.</p>
        <p>Software Engineer.</p>
      </div>
    </div>
  );
};

Banner.propTypes = {
  className: PropTypes.string,
};

Banner.defaultProps = {};

export default Banner;
