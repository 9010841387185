import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTrail, animated } from 'react-spring';
import NavbarContext from '../../layouts/NavBar/NavbarContext';
import { projectDb } from '../../lib/data';
import styles from './Projects/styles.module.scss';

const Projects = ({ className, data, id, ...rest }) => {
  const { activeSection } = React.useContext(NavbarContext);
  const trail = useTrail(Object.keys(data).length, {
    from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
    to: {
      opacity: activeSection === id ? 1 : 0,
      transform:
        activeSection === id ? 'translate3d(0,0,0)' : 'translate3d(0,40px,0)',
    },
  });
  return (
    <div className={cls(styles.container, 'py-4', className)} id={id} {...rest}>
      {trail.map((props, index) => {
        const p = data[Object.keys(data)[index]];
        return (
          <animated.div style={props} key={p.id}>
            <div
              data-label="card"
              className="tw-card flex flex-col h-full hover:bg-blue-100 md:hover:zoom-in-sm"
            >
              <div className="flex justify-between items-center">
                <div data-label="title" className="flex text-2xl font-bold">
                  {p.title}
                </div>
                <Link
                  className="card-hover-show tw-btn tw-btn-indigo"
                  key={p.id}
                  to={`/projects/${p.id}`}
                >
                  More
                </Link>
              </div>
              <div data-label="body" className={cls('mb-auto text-gray-700')}>
                <p>{p.description}</p>
              </div>
              <div className="card-hover-show text-sm text-gray-700">
                {p.technologies.map(({ label, src, link }) => (
                  <div key={label} className="inline-block">
                    <div className="tw-tooltip relative flex flex-col p-2 items-center md:hover:zoom-in-lg">
                      <a
                        className="relative"
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img alt={label} width={30} height={30} src={src} />
                        <p
                          data-label="tooltip-content"
                          className="absolute hidden md:block md:my-1 text-xs text-center bg-blue-700 text-white p-1 rounded whitespace-no-wrap"
                        >
                          {label}
                        </p>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </animated.div>
        );
      })}
    </div>
  );
};

Projects.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  data: PropTypes.object,
};

Projects.defaultProps = {
  data: projectDb,
};

export default Projects;
