import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NAVBAR_HEIGHT } from 'config/styles';
import AbsolutelyCentered from 'components/AbsolutelyCentered';
import { brands, navs } from './constant';
import NavItem from './NavItem';
import NavbarContext from './NavbarContext';

const NavBar = ({ className, ...rest }) => {
  const { setActiveSection } = React.useContext(NavbarContext);
  const [y, setY] = React.useState(0);
  const isNotTop = y !== 0;
  const scrollY = () => {
    const { pageYOffset } = window;
    setY(pageYOffset);
  };
  React.useEffect(() => {
    window.addEventListener('scroll', scrollY);
    return () => {
      window.removeEventListener('scroll', scrollY);
    };
  });
  return (
    <div className={cls('h-navbar overflow-x-scroll', className)} {...rest}>
      <nav
        className={cls(
          'flex',
          'w-full',
          'items-center',
          'fixed',
          'z-10',
          'h-navbar',
          'bg-white',
          'overflow-x-scroll',
          {
            'shadow-md': isNotTop,
          },
        )}
      >
        <div className="flex mr-auto">
          {brands.map((b) => (
            <a
              key={b.id}
              href={b.link}
              rel="noreferrer noopener"
              target="_blank"
              className="px-2 flex items-center"
              style={{ width: 50 }}
            >
              <img src={b.src} alt={b.id} />
            </a>
          ))}
        </div>
        <AbsolutelyCentered className="hidden md:block">
          {({ ref }) => (
            <NavItem to="navbar" className="text-3xl" hashSpy={false} ref={ref}>
              Me.
            </NavItem>
          )}
        </AbsolutelyCentered>
        {navs.map((n) => (
          <NavItem
            className={cls(
              'flex items-center h-full relative px-4 text-gray-700 hover:text-gray-600',
            )}
            activeClass="underline-spacing after:bg-blue-600 after:rounded after:left-5 after:right-5 text-blue-600 hover:text-blue-600"
            onSetActive={(id) => setActiveSection(id)}
            key={n.id}
            to={n.id}
            offset={-NAVBAR_HEIGHT}
          >
            {n.label}
          </NavItem>
        ))}
      </nav>
      {isNotTop && (
        <div className="md:hidden fixed z-20 bottom-0 right-0 mx-4 my-4 text-blue-700 font-bold">
          <NavItem to="navbar" hashSpy={false}>
            <FontAwesomeIcon size="2x" icon="chevron-up" />
          </NavItem>
        </div>
      )}
    </div>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
};

NavBar.defaultProps = {};

export default NavBar;
