import React from 'react';
import PropTypes from 'prop-types';
import NavbarContext from './NavbarContext';

const NavbarProvider = ({ children }) => {
  const [activeSection, setActiveSection] = React.useState(null);
  const context = React.useMemo(() => ({ activeSection, setActiveSection }), [
    activeSection,
    setActiveSection,
  ]);
  return (
    <NavbarContext.Provider value={context}>{children}</NavbarContext.Provider>
  );
};

NavbarProvider.propTypes = {
  children: PropTypes.any,
};

NavbarProvider.defaultProps = {};

export default NavbarProvider;
