import github from 'images/github.png';
import linkedin from 'images/linkedin.png';

export const navs = [
  {
    id: 'education',
    label: 'Education',
  },
  {
    id: 'experiences',
    label: 'Experiences',
  },
  {
    id: 'projects',
    label: 'Projects',
  },
  {
    id: 'contact',
    label: 'Contact',
  },
];
export const brands = [
  {
    id: 'github',
    src: github,
    link: 'https://github.com/jasonlimantoro',
  },
  {
    id: 'linkedin',
    src: linkedin,
    link: 'https://linkedin.com/in/jasonlimantoro',
  },
];
