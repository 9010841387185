import React from 'react';
import PropTypes from 'prop-types';
import { useTransitionUp } from 'hooks';
import { animated } from 'react-spring';
import cls from 'classnames';
import ntu from 'images/ntu.png';
import NavbarContext from '../../layouts/NavBar/NavbarContext';
import './Education/styles.scss';

const Education = ({ className, id, ...rest }) => {
  const { activeSection } = React.useContext(NavbarContext);
  const props = useTransitionUp(activeSection === id);
  return (
    <div
      className={cls('bg-red-700 flex items-center justify-center', className)}
      id={id}
      {...rest}
    >
      <animated.div
        data-label="education-card"
        className="flex flex-col bg-white w-full md:w-1/2 p-8 items-center rounded border-4"
        style={props}
      >
        <div data-label="card-logo" className="p-4">
          <img src={ntu} alt="school-logo" />
        </div>
        <div
          data-label="card-description"
          className="flex text-gray-700 text-sm md:text-2xl py-4"
        >
          <table>
            <tbody>
              <tr>
                <td>Major</td>
                <td>Computer Science</td>
              </tr>
              <tr>
                <td>Specialization</td>
                <td>Web Development</td>
              </tr>
              <tr></tr>
              <tr>
                <td>Graduated</td>
                <td>6/2021</td>
              </tr>
              <tr>
                <td>Tech Stack</td>
                <td>React, Redux, NodeJS, Django, Go, Docker, AWS</td>
              </tr>
            </tbody>
          </table>
        </div>
      </animated.div>
    </div>
  );
};

Education.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Education.defaultProps = {};

export default Education;
